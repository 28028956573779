import { Button, Dropdown, message, Popconfirm, Space } from 'antd';
import dayjs from 'dayjs';
import { compact, isNil } from 'lodash-es';
import { useNavigate } from 'react-router-dom';
import { isDevelopment } from '../../../config/local';
import { TrpcBase } from '../../../utils/trpc';
import { useUserAgent } from '../../../utils/userAgent';
import { useBuildingConfig } from '../useBuildingConfig';
import { categorySelectOption } from '../edit/categorySelectOption';
import { DownOutlined, SmileOutlined } from '@ant-design/icons';
import { useCopyFunc } from '../print/useCopyFunc';

export const ApplyItemMenuRender = ({ text, record }: any) => {
	useCopyFunc();
	const navigate = useNavigate();
	const { isMobile } = useUserAgent();

	const delAction = TrpcBase.apartment.del.useMutation();
	const { currentBuildingConfig } = useBuildingConfig();
	if (isNil(record?.uuid)) {
		return <div />;
	}

	return (
		<>
			<Dropdown
				menu={{
					items: compact([
						{
							key: '4',
							label: isMobile ? '详情' : '打印',
							onClick: () => {
								navigate(`/apartment/print?uuid=${record.uuid}`);
							},
						},
						{
							key: '5',
							disabled:
								!isDevelopment &&
								dayjs(record.checkInTime).isBefore(
									dayjs().startOf('year').startOf('month'),
								),
							onClick: () => {
								navigate(`/apartment/edit?uuid=${record.uuid}`);
							},
							label: '编辑',
						},

						(isDevelopment || isMobile) && {
							key: '6',
							label: (
								<Popconfirm
									title="确认进行删除吗?"
									onConfirm={async () => {
										const res = await delAction.mutateAsync({
											uuid: record.uuid,
										});

										message.success('删除成功');
									}}
									onCancel={() => {}}
									okText="确定"
									cancelText="取消"
								>
									删除
								</Popconfirm>
							),
						},

						{
							key: '2',
							label: (
								// biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
								<div
									onClick={(event) => {
										console.log(event.currentTarget);
										message.success('复制成功');
									}}
									className={'copy'}
									data-clipboard-text={getCopyText(
										record,
										currentBuildingConfig,
									)}
								>
									复制发送账单文案
								</div>
							),
						},

						{
							key: '10',
							label: (
								// biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
								<div
									onClick={() => {
										message.success('复制成功');
									}}
									className={'copy'}
									data-clipboard-text={`${dayjs(record.checkInTime).format(
										'YYYY年MM月',
									)} ${currentBuildingConfig?.buildingName} ${
										record.roomNumber
									} ${
										categorySelectOption.find(
											(i) => i.value === record.category,
										)?.label
									} https://i.ihook.center/apartment/print?uuid=${
										record?.uuid
									}`}
								>
									复制到表格
								</div>
							),
						},
					]),
				}}
			>
				{/* biome-ignore lint/a11y/useValidAnchor:asdasd */}
				<a onClick={(e) => e.preventDefault()}>
					<Space>
						操作
						<DownOutlined />
					</Space>
				</a>
			</Dropdown>
		</>
	);
};

function getCopyText(
	record: any,
	currentBuildingConfig: ReturnType<
		typeof useBuildingConfig
	>['currentBuildingConfig'],
): string {
	return `尊敬的租客，
  
提醒您本月的房租账单已经生成，并且已将账单链接生成: https://i.ihook.center/apartment/print?uuid=${record?.uuid} 。如有任何疑问，请立即与我们联系，我们将竭诚为您提供协助。
  
衷心感谢您对我们的信任和支持。我们相信您会继续保持良好的合作关系，共同维护${currentBuildingConfig?.buildingName}的和谐环境。
  
祝您生活愉快！ ${currentBuildingConfig?.buildingName}`;
}
